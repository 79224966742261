import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image';
import Helmet from 'react-helmet';
import parse from 'html-react-parser';
import {
    IoLogoFacebook,
    IoLogoTwitter,
    IoLogoInstagram,
    IoLogoLinkedin,
  } from 'react-icons/io';
import SocialProfile from '../components/social-profile/social-profile';
import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  AboutWrapper,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles,
} from '../containers/about/style';

/**
* Single page (/:slug)
*
* This file renders a single page and loads all the content.
*
*/
const Page = (props: any) => {
    const { data, location } = props;
    const page = data.ghostPage;
    // const featuredImage = useStaticQuery(graphql`
    // query FeaturedImage { 
    //   file(id: {eq: "${page.featureImageSharp}"}) {
    //     publicURL
    //     childImageSharp {
    //       fluid(maxWidth: 1170, quality: 90) {
    //         ...GatsbyImageSharpFluid_withWebp_tracedSVG
    //       }
    //     }
    //   }
    // }
    // `);

    const SocialLinks = [
        // {
        //   icon: <IoLogoFacebook />,
        //   url: 'https://www.facebook.com/redqinc/',
        //   tooltip: 'Facebook',
        // },
        {
          icon: <IoLogoInstagram />,
          url: 'https://www.instagram.com/yutingf/',
          tooltip: 'Instagram',
        },
        // {
        //   icon: <IoLogoTwitter />,
        //   url: 'https://twitter.com/redqinc',
        //   tooltip: 'Twitter',
        // },
        // {
        //   icon: <IoLogoLinkedin />,
        //   url: 'https://www.linkedin.com/company/redqinc/',
        //   tooltip: 'Linked In',
        // },
      ];

      console.log('do i have something', page);
      useEffect(() => {
        if ('instgrm' in window) {
          window.instgrm.Embeds.process();
        }
        document.querySelectorAll('.load-external-scripts a').forEach((a: any) => {
          if (!a.href.match(document.domain)) {
            a.target = '_blank';
          }
        });
      });

    return (
        <Layout>
        <SEO
            title={page.title}
            description={page.description || page.excerpt}
        />
        {page.codeinjection_head ? (
          <Helmet>
            {parse(page.codeinjection_head)}
          </Helmet>
        ) : ''}
        <AboutWrapper>
          {page.feature_image_sharp ? (
            <Img fluid={page.feature_image_sharp.childImageSharp.fluid} />
          ) : ''}
          <AboutPageTitle>
            <h2>{ page.title }</h2>
          </AboutPageTitle>
    
          <AboutDetails className="load-external-scripts" dangerouslySetInnerHTML={{ __html: page.html }} />
          <AboutDetails>
            <SocialProfiles>
              <SocialProfile items={SocialLinks} />
            </SocialProfiles>
          </AboutDetails>
        </AboutWrapper>
        </Layout>
        )
    // return (
    //     <>
    //         <MetaData
    //             data={data}
    //             location={location}
    //             type="website"
    //         />
    //         <Helmet>
    //             <style type="text/css">{`${page.codeinjection_styles}`}</style>
    //         </Helmet>
    //         <Layout>
    //             <div className="container">
    //                 <article className="content">
    //                     <h1 className="content-title">{page.title}</h1>

    //                     {/* The main page content */}
    //                     <section
    //                         className="content-body load-external-scripts"
    //                         dangerouslySetInnerHTML={{ __html: page.html }}
    //                     />
    //                 </article>
    //             </div>
    //         </Layout>
    //     </>
    // )
}

export default Page

export const postQuery = graphql`
    query($slug: String!) {
        ghostPage(slug: { eq: $slug }) {
            codeinjection_head
            codeinjection_foot
            title
            html
            description: meta_description
            excerpt
            feature_image_sharp {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1170, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
        }
    }
`
